import { MoreOutlined } from '@ant-design/icons';
import { Button, Card, Col, Dropdown, Grid, Row, Typography } from 'antd';
import { graphql, Link, PageProps } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import React from 'react';

import Container from '@/components/Container';
import Layout from '@/components/Layout';
import { createCategoryDropdownOverlayMenu } from '@/components/Navbar';
import SEO from '@/components/SEO';
import { useAllProductionInfo } from '@/hooks/useAllProductionInfo';
import { useGroupedProduction } from '@/hooks/useGroupedProduction';
import { findImageByBase } from '@/utils';
import { categoryPhotos } from '@/utils/mappings';
import { getCurrentBreakpoint } from '@/utils/mappings';

const categoryTitleSize = {
  xxl: 'text-2xl',
  xl: 'text-2xl',
  lg: 'text-xl',
  md: 'whitespace-normal',
  sm: 'whitespace-normal',
  xs: 'whitespace-normal',
};

const categoryImageStyle = {
  xxl: { height: 400 },
  xl: { height: 350 },
  lg: { height: 300 },
  md: { height: 400 },
  sm: { maxHeight: 500 },
  xs: { maxHeight: 500 },
};

const CategoryTemplate: React.FC<
  PageProps & {
    data: {
      allFile: {
        nodes: any[];
      };
    };
  }
> = ({
  data: {
    allFile: { nodes: categoryPhotoImages },
  },
  uri,
}) => {
  const screens = Grid.useBreakpoint();
  const currentBreakpoint = getCurrentBreakpoint(screens);
  const groupedProductsByCategory = useGroupedProduction();
  const allProductionInfo = useAllProductionInfo();

  return (
    <Layout>
      <SEO
        title="Каталог продукции"
        description="Косметическая продукция по уходу за кожей лица и тела, антисептики для рук, средства для очищения кожи и снятия макияжа, антицеллюлитные средства"
        uri={uri}
        image={null}
      />
      <Typography.Title className="text-center mt-6">
        Каталог продукции
      </Typography.Title>
      <Container>
        <Row align="middle" gutter={32}>
          {allProductionInfo.map(({ title: categoryName, slug }) => {
            const image = findImageByBase(
              categoryPhotoImages,
              categoryPhotos[categoryName].categoryPhoto
            );

            return (
              <Col xs={24} sm={24} lg={12} key={slug}>
                <Link to={`/catalog/${slug}`}>
                  <Card
                    hoverable
                    className="mt-4 mb-4 rounded-xl"
                    cover={
                      <>
                        <GatsbyImage
                          style={categoryImageStyle[currentBreakpoint]}
                          imgStyle={{
                            padding: '24px 24px 0',
                            objectFit: 'contain',
                            objectPosition: 'bottom center',
                          }}
                          image={image as any}
                          alt={categoryName}
                        />
                        <div
                          style={{
                            position: 'absolute',
                            top: 0,
                            left: '100%',
                            width: 48,
                            transform: 'translateX(-56px) translateY(8px)',
                          }}
                        >
                          <Dropdown
                            arrow
                            trigger={
                              currentBreakpoint === 'xs'
                                ? ['click']
                                : ['click', 'hover']
                            }
                            placement="bottomRight"
                            overlay={createCategoryDropdownOverlayMenu(
                              groupedProductsByCategory[categoryName]
                            )}
                          >
                            <Button
                              style={{ width: 48, height: 48 }}
                              icon={<MoreOutlined style={{ fontSize: 28 }} />}
                              shape="circle"
                              size="large"
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                              }}
                            />
                          </Dropdown>
                        </div>
                      </>
                    }
                  >
                    <Card.Meta
                      title={
                        <Typography.Title
                          className={`mb-0 text-center ${categoryTitleSize[currentBreakpoint]}`}
                          level={2}
                        >
                          {categoryName}
                        </Typography.Title>
                      }
                    />
                  </Card>
                </Link>
              </Col>
            );
          })}
        </Row>
      </Container>
    </Layout>
  );
};

export default CategoryTemplate;

export const pageQuery = graphql`
  query Catalog {
    allFile(filter: { sourceInstanceName: { eq: "categories" } }) {
      nodes {
        id
        base
        childImageSharp {
          gatsbyImageData(height: 350)
        }
      }
    }
  }
`;
